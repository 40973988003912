import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { Link } from 'react-router-dom'
import some from 'lodash/some'
import map from 'lodash/map'
import S3 from 'aws-sdk/clients/s3'
import { IApplicationState } from '@Site/rootReducer'
import Header from '@SiteContainers/Header'
import UploadArea from './UploadArea'
import SubmitUrlsArea from './SubmitUrlsArea'
import UploadingProgress from './UploadingProgress'
import Loader from '@SiteComponents/Loader'
import ElementUploader, { IElementUploaderOptions, IUploadedElement } from '@Utils/ElementUploader'

import Return from '@Images/return_from_pv_settings.svg'

import {
  initializeUpload,
  createElement,
  resetUpload,
  submitUrls,
} from '@SiteModules/Element/actions'

import {
  createProject
} from '@SiteModules/Project/actions'

import {
  IInitializeUploadPayload,
  ISubmitUrlsPayload,
} from '@SiteModules/Element/types'

import {
  resetLabelSelection,
} from '@SiteModules/Label/actions'

import './Upload.scss'

const DEFAULT_STRIKE_COLOR = '#43C78F' // bg_default

interface UploadProps {
  readonly uploading: boolean
  readonly maxFileSize: number | null
  readonly workspaceId: number
  readonly numberOfFilesToUpload: number
  readonly alreadyUploaded: number
  readonly isNewUser: boolean
  readonly remainingElementStorageSpace: number
  readonly usedElementStorageSpace: number
  readonly maxElementStorageSpace: number
  initializeUpload(payload: IInitializeUploadPayload): void
  createElement(payload: IUploadedElement): void
  resetUpload(): void
  submitUrls(payload: ISubmitUrlsPayload): void
  createProject(): void
  resetLabelSelection(): void
}

interface UploadState {
  readonly filesToUpload: File[]
  readonly progress: number
  readonly strikeColor: string
  readonly uploadingFileName: string
  readonly showUploadingProgress: boolean
  readonly cancelUploadingCounter: number
  readonly warningMessages: string[]
  readonly showRemainingDiskSpaceWarning: boolean
}

let uploadingRequest: S3.ManagedUpload

class Upload extends React.Component<UploadProps, UploadState> {
  DEFAULT_MAX_VIDEO_SIZE = 4294967296 // 4Gb
  MAX_NUMBER_OF_URLS_TO_SUBMIT = 4
  BYTES_PER_GIGABYTE = 1073741824
  maxVideoSize = this.props.maxFileSize || this.DEFAULT_MAX_VIDEO_SIZE
  dragEnterCounter = 0

  state: UploadState = {
    filesToUpload: [],
    progress: 0,
    strikeColor: DEFAULT_STRIKE_COLOR,
    uploadingFileName: '',
    showUploadingProgress: false,
    cancelUploadingCounter: 0,
    warningMessages: [],
    showRemainingDiskSpaceWarning: false
  }

  fileRef: React.RefObject<HTMLInputElement>

  constructor(props: UploadProps) {
    super(props)

    props.resetLabelSelection()
    this.fileRef = React.createRef()
  }

  componentDidUpdate(prevProps: UploadProps) {
    const { filesToUpload, showUploadingProgress } = this.state
    const { uploading, numberOfFilesToUpload, alreadyUploaded } = this.props
    const shouldUploadNextFile = prevProps.uploading !== uploading && uploading === false && alreadyUploaded < numberOfFilesToUpload
    const shouldStopShowUploadingProgress = alreadyUploaded === numberOfFilesToUpload && alreadyUploaded !== 0

    if (showUploadingProgress) {
      if (shouldUploadNextFile) {
        this.uploadFile(filesToUpload[alreadyUploaded])
      }

      if (shouldStopShowUploadingProgress) {
        this.setState({ showUploadingProgress: false })
        window.location.href='/'
      }
    }
  }

  setWarningMessages = (newWarningMessages: string[]) => {
    this.setState({ warningMessages: newWarningMessages })
  }

  setShouldShowRemainingDiskSpaceWarning = (newShouldShowRemainingDiskspaceWarning: boolean) => {
    this.setState({ showRemainingDiskSpaceWarning: newShouldShowRemainingDiskspaceWarning })
  }

  openFileManager = () => {
    if (!this.fileRef) { return }
    if (!this.fileRef.current) { return }

    this.fileRef.current.click()
  }

  onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    const filesList = e.dataTransfer.files
    if (filesList) {
      const filesToUpload = Array.from(filesList).filter((item) => {
        return item.type.startsWith('video/') || item.type.startsWith('audio/')
      })

      if (filesToUpload.length > 0) {
        this.handleUpload(filesToUpload)
      }
    }
  }

  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (this.dragEnterCounter === 0) {
      e.currentTarget.classList.add('Upload__upload-area-dragOver')
    }
    this.dragEnterCounter++
  }

  handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    this.dragEnterCounter--
    if (this.dragEnterCounter === 0) {
      e.currentTarget.classList.remove('Upload__upload-area-dragOver')
    }
  }

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  handleUpload = (filesToUpload: File[]) => {
    this.setWarningMessages([])
    this.setShouldShowRemainingDiskSpaceWarning(false)

    // if (reduce(filesToUpload, (totalSize, file: File) => totalSize + file.size, 0) > this.props.remainingElementStorageSpace){
    //   this.setShouldShowRemainingDiskSpaceWarning(true)
    //   return
    // }

    if (some(filesToUpload, (file: File) => file.size > this.maxVideoSize)){
      this.setWarningMessages([`Element(s) cannot be uploaded. At least one element is too large (> ${(this.maxVideoSize / this.BYTES_PER_GIGABYTE).toFixed(0)} GB)`])
      return
    }

    this.props.initializeUpload({ numberOfFilesToUpload: filesToUpload.length })

    this.setState({ filesToUpload }, () => {
      this.uploadFile(filesToUpload[0])
    })
  }

  handleUploadClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) { return }

    this.handleUpload(Array.from(files))
  }

  uploadFile = (file: File) => {
    const options: IElementUploaderOptions = {
      uploadElementCallback: (params: IUploadedElement) => {
        this.setState({ progress: 100 })
        this.props.createElement(params)
      },
      showProgressCallback: (data: S3.ManagedUpload.Progress) => {
        const progress = data.loaded / file.size * 100
        this.setState({ progress })
      },
      workspaceId: this.props.workspaceId
    }

    uploadingRequest = new ElementUploader(file, options).call()
    this.setState({
      progress: 0,
      strikeColor: DEFAULT_STRIKE_COLOR,
      uploadingFileName: file.name,
      showUploadingProgress: true
    })
  }

  cancelUploading = () => {
    const shouldShowDashboard = this.props.alreadyUploaded > 0

    uploadingRequest.abort()
    this.props.resetUpload()
    this.setState({
      progress: 0,
      strikeColor: DEFAULT_STRIKE_COLOR,
      uploadingFileName: '',
      showUploadingProgress: false,
      filesToUpload: [],
      cancelUploadingCounter: this.state.cancelUploadingCounter + 1
    })

    if (shouldShowDashboard) {
      window.location.href = '/'
    }
  }

  goToProjectTypeSelection = () => {
    window.location.href = '/project_type_selection'
  }

  renderUploadingProgress = () => {
    const { progress, strikeColor, uploadingFileName } = this.state
    const { alreadyUploaded, numberOfFilesToUpload } = this.props

    return(
      <div className="Upload__uploading-progress">
        <div className="Upload__header">We're uploading your video(s)</div>
        <UploadingProgress
          progress={progress}
          strikeColor={strikeColor}
          cancelUploading={this.cancelUploading}
          uploadingFileName={uploadingFileName}
          alreadyUploaded={alreadyUploaded}
          numberOfFilesToUpload={numberOfFilesToUpload}
        />
      </div>
    )
  }

  renderBackButton = () => {
    return (
      <div
        className="Upload__form-return"
        onClick= {this.goToProjectTypeSelection}
      >
        <Return />
      </div>
    )
  }

  renderUploadForm = () => {
    return (
      <div className="Upload__form">
        {this.renderBackButton()}
        <div className="Upload__form-top">
          <div className="Upload__header">Upload your video</div>
          <div className="Upload__subheader">Add up to 50 videos at once</div>
          {this.renderUploadArea()}
          {this.renderSubmitUrlsArea()}
          {this.renderWarnings()}
        </div>
        <div className="Upload__form-bottom">
          {this.renderStartWithBlankProject()}
        </div>
      </div>
    )
  }

  renderUploadArea = () => {
    return (
      <UploadArea
        key={this.state.cancelUploadingCounter} // Necessary to trigger onChange for the input if canceling a first upload and uploading the same files again
        fileRef={this.fileRef}
        openFileManager={this.openFileManager}
        onFileDrop={this.onFileDrop}
        handleDragEnter={this.handleDragEnter}
        handleDragLeave={this.handleDragLeave}
        handleUpload={this.handleUpload}
        handleUploadClick={this.handleUploadClick}
        handleDragOver={this.handleDragOver}
        shouldRenderFreeTrialMessage={this.props.isNewUser}
      />
    )
  }

  renderSubmitUrlsArea = () => {
    return (
      <SubmitUrlsArea
        uploading={this.props.uploading}
        submitUrls={this.props.submitUrls}
        remainingElementStorageSpace={this.props.remainingElementStorageSpace}
        setWarningMessages={this.setWarningMessages}
        setShouldShowRemainingDiskSpaceWarning={this.setShouldShowRemainingDiskSpaceWarning}
        maxNumberOfUrlsToSubmit={this.MAX_NUMBER_OF_URLS_TO_SUBMIT}
        placeholder={<span>Or paste your URLs here... <br />Add one URL per line. We support links from YouTube, Vimeo and more.</span>}
      />
    )
  }

  renderWarnings = () => {
    const shouldShowWarningList = this.state.warningMessages.length > 0
    const usedElementStorageSpace = (this.props.usedElementStorageSpace / this.BYTES_PER_GIGABYTE).toFixed(0)
    const maxElementStorageSpace = (this.props.maxElementStorageSpace / this.BYTES_PER_GIGABYTE).toFixed(0)

    return (
      <div className="Upload__warnings">
        {this.state.showRemainingDiskSpaceWarning && (
          <div className="Upload__warning-diskspace">
            {`You are using ${usedElementStorageSpace} GB of the ${maxElementStorageSpace} GB included in your plan.`}<br/>
            <Link to="/?showAssets=true">
              Delete assets
            </Link>
            {' before uploading new ones or '}
            <Link to="/subscriptions">
              upgrade your plan
            </Link>
            .
          </div>
        )}
        {shouldShowWarningList && (
          <ul className="Upload__warnings-list">
            {map(this.state.warningMessages, (warningMessage, index) => {
              return (
                <li key={index}>
                  {warningMessage}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    )
  }

  renderStartWithBlankProject = () => {
    return (
      <React.Fragment>
        <div className="Upload__start-with-blank-project-header">
          Or start with a blank project
        </div>
        <div
          className="Upload__create-empty-project"
          onClick={this.props.createProject}
        >
          Create empty project
        </div>
      </React.Fragment>
    )
  }

  renderLoader = () => {
    return (
      <Loader color="dark" />
    )
  }

  render() {
    const shouldNotLinkToDashboard = this.props.isNewUser || this.state.showUploadingProgress || this.props.uploading
    const shouldDisableProfileButton = this.state.showUploadingProgress || this.props.uploading
    const shouldRenderLoader = this.props.uploading && !this.state.showUploadingProgress
    const shouldRenderUploadingProgress = this.state.showUploadingProgress
    const shouldRenderUploadForm = !shouldRenderLoader && !shouldRenderUploadingProgress

    return (
      <div className="Upload">
        <Header
          shouldNotLinkToDashboard={shouldNotLinkToDashboard}
          shouldDisableProfileButton={shouldDisableProfileButton}
        />
        {shouldRenderLoader && this.renderLoader()}
        {shouldRenderUploadForm && this.renderUploadForm()}
        {shouldRenderUploadingProgress && this.renderUploadingProgress()}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, elements } = state

  return {
    uploading: elements.uploading,
    numberOfFilesToUpload: elements.numberOfFilesToUpload,
    alreadyUploaded: elements.alreadyUploaded,
    maxFileSize: user.user.attributes.maxFileSize,
    workspaceId: user.user.attributes.workspaceId,
    isNewUser: user.user.attributes.isNewUser,
    remainingElementStorageSpace: user.user.attributes.remainingElementStorageSpace,
    usedElementStorageSpace: user.user.attributes.usedElementStorageSpace,
    maxElementStorageSpace: user.user.attributes.maxElementStorageSpace,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    initializeUpload,
    createElement,
    resetUpload,
    submitUrls,
    createProject,
    resetLabelSelection,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
