import { IAction, TActions } from '@Root/types'

export const SubscriptionsActionTypes = {
  CANCEL_SUBSCRIPTION: '@@subscription/CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_SUCCESS: '@@subscription/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: '@@subscription/CANCEL_SUBSCRIPTION_FAILURE',

  UPDATE_SUBSCRIPTION: '@@subscription/UPDATE_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION_SUCCESS: '@@subscription/UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAILURE: '@@subscription/UPDATE_SUBSCRIPTION_FAILURE',

  START_CHECKOUT_SESSION: '@@subscription/START_CHECKOUT_SESSION',
  START_CHECKOUT_SESSION_FAILURE: '@@subscription/START_CHECKOUT_SESSION_FAILURE',
}

export interface ISubscriptionState {
  readonly hasLoadedSuccessfully: boolean
}

export interface ISubscription {
  readonly id: string
  readonly type: 'subscription',
  readonly attributes: {
    readonly plan: string
    readonly maxLanguagesLimit: number
    readonly secondsAvailable: number
    readonly minutesProvided: number
    readonly minutesProvidedByPeriod: number
    readonly isCurrent: boolean
    readonly isCanceled: boolean
    readonly isTrialing: boolean
    readonly cancelAtPeriodEnd: boolean
    readonly hasCreditLikeCharging: boolean
    readonly renewalDate: string
    readonly allowsVoice: boolean
    readonly allowsVoiceCloning: boolean
    readonly allowsTranslationAdaptionForDubbing: boolean
    readonly allowsLipSyncing: boolean
  }
  readonly price: IPrice
}

export interface IPrice {
  readonly id: string,
  readonly type: 'price'
  readonly attributes: {
    readonly amount: number
    readonly vat: number
    readonly shouldApplyVat: boolean
    readonly currency: string
  }
}

export interface ISubscriptionBody {
  readonly data: ISubscription
}

export interface ICancelSubscriptionPayload {
  readonly cancellationReason?: string
}

export interface IStartCheckoutSessionPayload {
  readonly planName: string,
  readonly currency: string
}

export interface IUpdateSubscriptionPayload {
  readonly subscriptionId: string,
  readonly planName: string,
}

export interface IUpdateSubscriptionSuccessResponseBody {
  readonly data: ISubscription
}

export type ICancelSubscriptionSuccessResponseBody = IUpdateSubscriptionSuccessResponseBody

export interface IUpdateSubscriptionSuccessAction extends IAction {
  readonly payload: ISubscription
}

export type ICancelSubscriptionSuccessAction = IUpdateSubscriptionSuccessAction

export type TSubscriptionsActionTypes =
  TActions<ISubscription> |
  TActions<ICancelSubscriptionPayload> |
  IUpdateSubscriptionSuccessAction |
  ICancelSubscriptionSuccessAction
