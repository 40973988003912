import { History } from 'history'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { projectVersionsListReducer } from '@SiteContainers/ProjectVersionsList/reducer'
import { dashboardSidebarReducer} from '@SiteContainers/DashboardSidebar/reducer'
import { dashboardMenuReducer} from '@SiteContainers/DashboardMenu/reducer'
import { userSignInReducer } from '@SitePages/UsersSignIn/reducer'
import { userSignUpReducer } from '@SitePages/UsersSignUp/reducer'
import { userPasswordEditReducer } from '@SitePages/UsersPasswordEdit/reducer'
import { userPasswordNewReducer } from '@SitePages/UsersPasswordNew/reducer'
import { userConfirmationNewReducer } from '@SitePages/UsersConfirmationNew/reducer'
import { subscriptionReducer } from '@SiteModules/Subscription/reducer'
import { projectVersionSettingsReducer } from '@SitePages/ProjectVersionSettings/reducer'
import { projectVersionSettingsTtsReducer } from '@SitePages/ProjectVersionSettingsTts/reducer'
import { externalTokenReducer } from '@SiteContainers/ExternalTokenPopup/reducer'
import { deleteProjectVersionReducer } from '@SiteContainers/DeleteProjectVersionPopup/reducer'
import { deleteLabelReducer } from '@SiteContainers/DeleteLabelPopup/reducer'
import { labelsReducer } from '@SiteModules/Label/reducer'
import { importSrtReducer } from '@SiteContainers/ImportSrtPopup/reducer'
import { userReducer } from '@SiteModules/User/reducer'
import { vocabulariesReducer } from '@SiteModules/Vocabulary/reducer'
import { elementsReducer } from '@SiteModules/Element/reducer'
import { channelsTableReducer } from '@SiteContainers/ChannelsTable/reducer'
import { assetsTableReducer } from '@SiteContainers/AssetsTable/reducer'
import { themesReducer } from '@SiteContainers/Themes/reducer'
import { renameLabelReducer } from '@SiteContainers/RenameLabelPopup/reducer'
import { demoReducer } from '@SiteModules/Demo/reducer'
import { exportationsReducer } from '@SiteModules/Exportation/reducer'
import { processingReducer } from '@SitePages/Processing/reducer'
import { guidelinesReducer } from '@SiteModules/Guideline/reducer'
import { termbasesReducer } from '@SiteModules/Termbase/reducer'

import { IUserSignInState } from '@SitePages/UsersSignIn/types'
import { IUserSignUpState } from '@SitePages/UsersSignUp/types'
import { IUserPasswordEditState } from '@SitePages/UsersPasswordEdit/types'
import { IUserPasswordNewState } from '@SitePages/UsersPasswordNew/types'
import { IUserConfirmationNewState } from '@SitePages/UsersConfirmationNew/types'
import { IProjectVersionsListState } from '@SiteContainers/ProjectVersionsList/types'
import { IDashboardSidebarState } from '@SiteContainers/DashboardSidebar/types'
import { IDashboardMenuState } from '@SiteContainers/DashboardMenu/types'
import { IProjectVersionSettingsState } from '@SitePages/ProjectVersionSettings/types'
import { IProjectVersionSettingsTtsState } from '@SitePages/ProjectVersionSettingsTts/types'
import { IExternalTokenState } from '@SiteContainers/ExternalTokenPopup/types'
import { ILabelState } from '@SiteModules/Label/types'
import { IDeleteProjectVersionState } from '@SiteContainers/DeleteProjectVersionPopup/types'
import { IDeleteLabelState } from '@SiteContainers/DeleteLabelPopup/types'
import { IImportSrtState } from '@SiteContainers/ImportSrtPopup/types'
import { ISubscriptionState } from '@SiteModules/Subscription/types'
import { IUserState } from '@SiteModules/User/types'
import { IVocabularyState } from '@SiteModules/Vocabulary/types'
import { IElementState } from '@SiteModules/Element/types'
import { IChannelsTableState } from '@SiteContainers/ChannelsTable/types'
import { IAssetsTableState } from '@SiteContainers/AssetsTable/types'
import { IThemesState } from '@SiteContainers/Themes/types'
import { IRenameLabelState } from '@SiteContainers/RenameLabelPopup/types'
import { IDemoState } from '@SiteModules/Demo/types'
import { IExportationState } from '@SiteModules/Exportation/types'
import { IProcessingState } from '@SitePages/Processing/types'
import { IGuidelineState } from '@SiteModules/Guideline/types'
import { ITermbaseState } from '@SiteModules/Termbase/types'

export interface IApplicationState {
  readonly user: IUserState
  readonly labels: ILabelState
  readonly router: History<any>
  readonly elements: IElementState
  readonly importSrt: IImportSrtState
  readonly deleteLabel: IDeleteLabelState
  readonly projectVersionsList: IProjectVersionsListState
  readonly userSignIn: IUserSignInState
  readonly userSignUp: IUserSignUpState
  readonly vocabularies: IVocabularyState
  readonly deleteProjectVersion: IDeleteProjectVersionState
  readonly subscription: ISubscriptionState
  readonly projectVersionSettings: IProjectVersionSettingsState
  readonly projectVersionSettingsTts: IProjectVersionSettingsTtsState
  readonly externalToken: IExternalTokenState
  readonly userPasswordNew: IUserPasswordNewState
  readonly userPasswordEdit: IUserPasswordEditState
  readonly dashboardSidebar: IDashboardSidebarState
  readonly dashboardMenu: IDashboardMenuState
  readonly channelsTable: IChannelsTableState
  readonly assetsTable: IAssetsTableState
  readonly userConfirmationNew: IUserConfirmationNewState
  readonly themes: IThemesState
  readonly renameLabel: IRenameLabelState
  readonly demo: IDemoState
  readonly exportations: IExportationState
  readonly processing: IProcessingState
  readonly guidelines: IGuidelineState
  readonly termbases: ITermbaseState
}

const rootReducer = (history: History<any>) => combineReducers({
  user: userReducer,
  labels: labelsReducer,
  router: connectRouter(history),
  importSrt: importSrtReducer,
  renameLabel: renameLabelReducer,
  projectVersionsList: projectVersionsListReducer,
  userSignIn: userSignInReducer,
  userSignUp: userSignUpReducer,
  deleteProjectVersion: deleteProjectVersionReducer,
  deleteLabel: deleteLabelReducer,
  vocabularies: vocabulariesReducer,
  subscription: subscriptionReducer,
  projectVersionSettings: projectVersionSettingsReducer,
  projectVersionSettingsTts: projectVersionSettingsTtsReducer,
  externalToken: externalTokenReducer,
  userPasswordNew: userPasswordNewReducer,
  dashboardMenu: dashboardMenuReducer,
  dashboardSidebar: dashboardSidebarReducer,
  channelsTable: channelsTableReducer,
  assetsTable: assetsTableReducer,
  userPasswordEdit: userPasswordEditReducer,
  userConfirmationNew: userConfirmationNewReducer,
  elements: elementsReducer,
  themes: themesReducer,
  demo: demoReducer,
  exportations: exportationsReducer,
  processing: processingReducer,
  guidelines: guidelinesReducer,
  termbases: termbasesReducer,
})

export default rootReducer
