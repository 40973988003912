import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import history from '@Root/historyInstance'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'

import autoDownloadExportation from '@Utils/AutoDownloadExportation'

import NoMatch from '@SitePages/NoMatch'
import Dashboard from '@SitePages/Dashboard'
import Vocabularies from '@SitePages/Vocabularies'
import Guidelines from '@SitePages/Guidelines'
import Termbases from '@SitePages/Termbases'
import UsersSignUp from '@SitePages/UsersSignUp'
import UsersSignIn from '@SitePages/UsersSignIn'
import Survey from '@SitePages/Onboarding/Survey'
import Subscription from '@SitePages/Subscription'
import UsersPasswordNew from '@SitePages/UsersPasswordNew'
import UsersPasswordEdit from '@SitePages/UsersPasswordEdit'
import UsersConfirmationNew from '@SitePages/UsersConfirmationNew'
import CheckEmail from '@SitePages/Onboarding/CheckEmail'
import ChannelsNew from '@SitePages/ChannelsNew'
import ChannelsManage from '@SitePages/ChannelsManage'
import ProjectVersionSettings from '@SitePages/ProjectVersionSettings'
import ProjectVersionSettingsTts from '@SitePages/ProjectVersionSettingsTts'
import Upload from '@SitePages/Upload'
import ProjectTypeSelection from '@SitePages/ProjectTypeSelection'
import PageLoader from '@EditorComponents/PageLoader'
import DemoUpload from '@SitePages/DemoUpload'
import DemoProjectVersionSettings from '@SitePages/DemoProjectVersionSettings'
import Alert from '@SiteComponents/Alert'
import Processing from '@SitePages/Processing'

import {
  IAlert,
  ISetLastViewedAlert,
} from '@SiteModules/User/types'

import {
  IGetExportationsForDownloadPayload,
  IExportationForDownload,
  IRemoveFromExportationsForDownloadPayload,
} from '@EditorModules/Exportation/types'

import {
  getUserInfo,
  setLastViewedAlert,
  getExportingSubtitleIds,
} from '@SiteModules/User/actions'

import {
  getExportationsForDownload,
  removeFromExportationsForDownload,
} from '@SiteModules/Exportation/actions'

import { IApplicationState } from './rootReducer'

interface ChecksubAppProps {
  readonly shouldGetUserInfo: boolean
  readonly relevantAlert: IAlert | null
  readonly exportingSubtitleIds: number[]
  readonly exportationsForDownload: IExportationForDownload[]
  getUserInfo(): void
  setLastViewedAlert(payload: ISetLastViewedAlert): void
  getExportingSubtitleIds(): void
  getExportationsForDownload(payload: IGetExportationsForDownloadPayload): void
  removeFromExportationsForDownload(payload: IRemoveFromExportationsForDownloadPayload): void
}

interface ChecksubAppState {
  readonly shouldWait: boolean
  readonly intervalId: number
}

const DELAY = 3000
const AUTO_UPDATE_TIME_EXPORTATIONS= 30000 // 30 seconds

class ChecksubApp extends React.Component<ChecksubAppProps, ChecksubAppState> {
  constructor(props: ChecksubAppProps) {
    super(props)
    this.state = {
      shouldWait: true,
      intervalId: -1
    }
  }

  componentDidMount() {
    const { shouldGetUserInfo } = this.props

    setTimeout(() => {
      this.setState({ shouldWait: false })
      if (shouldGetUserInfo) { this.props.getUserInfo() }
    }, window.location.href.includes('paid=true') ? DELAY : 0)
  }

  componentDidUpdate(prevProps: ChecksubAppProps) {
    this.setTimerForUpdatingExportingSubtitleIds()

    const recentlyFinishedExportationSubtitleIds = filter(prevProps.exportingSubtitleIds, previousExportingSubtitleId => !this.props.exportingSubtitleIds.includes(previousExportingSubtitleId))
    forEach(recentlyFinishedExportationSubtitleIds, recentlyFinishedExportationSubtitleId => {
      setTimeout(() => this.props.getExportationsForDownload({ subtitleId: recentlyFinishedExportationSubtitleId }), DELAY)
    })

    const exportationsReadyForDownload = filter(this.props.exportationsForDownload, exportationForDownload => !prevProps.exportationsForDownload.includes(exportationForDownload))
    forEach(exportationsReadyForDownload, exportationReadyForDownload => {
      autoDownloadExportation(exportationReadyForDownload)
      this.props.removeFromExportationsForDownload({ id: exportationReadyForDownload.id })
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  setTimerForUpdatingExportingSubtitleIds = () => {
    const { intervalId } = this.state
    const shouldUpdateOngoingExportations = this.props.exportingSubtitleIds.length > 0
    const isTimerPresent = intervalId !== -1
    const shouldSetupTimerForFetchingUpdates = shouldUpdateOngoingExportations && !isTimerPresent
    const shouldRemoveInterval = !shouldUpdateOngoingExportations && isTimerPresent

    if (shouldSetupTimerForFetchingUpdates) {
      const newTimerId = window.setInterval(this.props.getExportingSubtitleIds, AUTO_UPDATE_TIME_EXPORTATIONS)
      this.setState({ intervalId: newTimerId })
    } else if (shouldRemoveInterval) {
      clearInterval(this.state.intervalId)
      this.setState({ intervalId: -1 })
    }
  }

  closeAlert = () => {
    if (!this.props.relevantAlert?.id) { return }

    this.props.setLastViewedAlert({ alertId: this.props.relevantAlert.id })
  }

  render() {
    return(
      <ConnectedRouter history={history}>
        {this.state.shouldWait ? (
          <PageLoader/>
        ) : (
          <React.Fragment>
            {this.props.relevantAlert?.message && (
              <Alert
                message={this.props.relevantAlert.message}
                close={this.closeAlert}
              />
            )}
            <Switch>
              <PublicRoute exact restricted={false} path="/subscriptions" component={Subscription} />
              <PublicRoute exact restricted={false} path="/channels/new" component={ChannelsNew} />
              <PublicRoute exact restricted={false} path="/channels/manage" component={ChannelsManage} />
              <PublicRoute exact restricted={false} path="/demo/upload" component={DemoUpload} />
              <PublicRoute exact restricted={false} path="/demo/setup" component={DemoProjectVersionSettings} />

              <PublicRoute exact restricted path="/users/sign_up" component={UsersSignUp} />
              <PublicRoute exact restricted path="/users/sign_in" component={UsersSignIn} />
              <PublicRoute exact restricted path="/users/password/new" component={UsersPasswordNew} />
              <PublicRoute exact restricted path="/users/confirmation/new" component={UsersConfirmationNew} />
              <PublicRoute exact restricted path="/users/password/edit" component={UsersPasswordEdit} />

              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute exact path="/customizations/vocabularies" component={Vocabularies} />
              <PrivateRoute exact path="/customizations/guidelines" component={Guidelines} />
              <PrivateRoute exact path="/customizations/termbases" component={Termbases} />
              <PrivateRoute exact path="/project_type_selection" component={ProjectTypeSelection} />
              <PrivateRoute exact path="/upload" component={Upload} />
              <PrivateRoute exact path="/setup" component={ProjectVersionSettings} />
              <PrivateRoute exact path="/setup_tts" component={ProjectVersionSettingsTts} />
              <PrivateRoute exact path="/processing" component={Processing} />

              <PrivateRoute exact path="/onboarding/check_email" component={CheckEmail} />
              <PrivateRoute exact path="/onboarding" component={Survey} />

              <Route path="*" component={NoMatch} />
            </Switch>
          </React.Fragment>
        )}
      </ConnectedRouter>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, exportations } = state
  const isAuthorized = window.Checksub.cookies.get('authorized') === 'true'

  return {
    shouldGetUserInfo: isAuthorized && user.user.id === '',
    relevantAlert: user.user.attributes.relevantAlert,
    exportingSubtitleIds: user.user.attributes.exportingSubtitleIds,
    exportationsForDownload: exportations.exportationsForDownload,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    getUserInfo,
    setLastViewedAlert,
    getExportingSubtitleIds,
    getExportationsForDownload,
    removeFromExportationsForDownload,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecksubApp)
